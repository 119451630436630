export const ReportList = [
  {
    type: "ad",
    name: "广告引流",
    desc: "可能含有商品售卖、虚假兼职、诱导性引流等内容。",
  },
  {
    type: "porn",
    name: "色情低俗",
    desc: "可能含有展示或淫秽引导淫秽色情、诱惑低俗等内容。",
  },

  {
    type: "political",
    name: "政治敏感",
    desc: "可能含有非权威媒体发布的有关时政的不实、谣言等争议内容。",
  },
  {
    type: "illegal",
    name: "违法有害",
    desc: "可能含有血腥暴力、管制枪械道具、违法赌博等相关内容。",
  },
  {
    type: "young",
    name: "未成年有害",
    desc: "可能含有未成年抽烟、早恋等不良行为，侵害未成年身心健康及未成年低俗等内容。",
  },
  {
    type: "falseIdentity",
    name: "身份作假",
    desc: "可能含有作假的姓名、单位、部门、职位、在职状态等身份信息",
  },
  {
    type: "infringement",
    name: "内容侵权",
    desc: "搬运抄袭、或侵犯肖像、隐私、名誉、商标、专利权。",
  },
  {
    type: "fraud",
    name: "诈骗信息",
    desc: "可能含有会造成生命财产损失的诈骗信息。",
  },
  {
    type: "others",
    name: "有其他问题",
    desc: "可能含有其他不良内容",
  },
];
