import globalData from "./data.json";
import pos from "./pos.json";

export const TOKEN = "2";

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export function hideLastTwoDigits(phoneNumber) {
  const str = String(phoneNumber);
  return str.slice(0, str.length - 2) + "**";
}

function getRandom(n, m) {
  var num = Math.floor(Math.random() * (m - n + 1) + n);
  return num;
}

const truncateCityName = (name) => {
  if (!name) {
    return;
  }
  return name.length > 4 ? name.slice(0, 5) + ".." : name;
};

export function getCountrys() {
  const buffers = [];
  for (let i = 0; i < globalData.length; i++) {
    const countryName = globalData[i].country;
    if (buffers.indexOf(countryName) > -1) {
      continue;
    } else {
      buffers.push(countryName);
    }
  }
  return buffers;
}

getCountrys();
const generateRandomData = () => {
  return Array.from({ length: getCountrys().length }, () =>
    Math.floor(Math.random() * 100 + 300)
  );
};
export function getSeries() {
  return getCountrys().map((name) => [
    {
      name,
      type: "bar",
      stack: "总量",
      label: {
        show: true,
        position: "insideRight",
      },
      data: generateRandomData(),
    },
  ]);
}

export function getCitys() {
  let cityList = [];
  let countries = getCountrys();

  countries.map((name) => {
    const matchData = globalData.filter((item) => item.country === name);
    matchData.forEach((data) => {
      if (data.city) {
        cityList.push({
          countryName: truncateCityName(data.country),
          cityName: truncateCityName(data.city),
          weight: getRandom(15, 20),
        });
      }
    });
  });
  return cityList;
}
// 标注点坐标
export const getPoints = () => {
  const buffer = pos.forEach((item) => {});
};

export const checkCity = ["威海", "日照"];

export const viewport = () => {
  var designWidth = 2640; // 设计稿宽度
  var viewport = document.querySelector("meta[name=viewport]");

  // 定义缩放函数
  function setScale() {
    var scale = 0.5;
    viewport.setAttribute(
      "content",
      "width=" + designWidth + ", initial-scale=" + scale
    );
  }

  // 初次加载时设置缩放比例
  setScale();

  // 监听窗口调整大小事件（包括屏幕旋转）
  window.addEventListener("resize", function () {
    setScale();
  });
};

export const getCityByCountry = (name) => {
  return globalData.filter((item) => item.country === name);
};
const flags = [
  {
    name: "中国",
    emoji: "🇨🇳",
  },
  {
    name: "美国",
    emoji: "🇺🇸",
  },
  {
    name: "加拿大",
    emoji: "🇨🇳",
  },
  {
    name: "俄罗斯",
    emoji: "🇷🇺",
  },
  {
    name: "沙特",
    emoji: "🇸🇦",
  },
  {
    name: "澳大利亚",
    emoji: "🇦🇺",
  },
  {
    name: "泰国",
    emoji: "🇹🇭",
  },
  {
    name: "越南",
    emoji: "🇻🇳",
  },
  {
    name: "英国",
    emoji: "🇬🇧",
  },
  {
    name: "德国",
    emoji: "🇩🇪",
  },
  {
    name: "斯里兰卡",
    emoji: "🇱🇰",
  },
  {
    name: "巴拉圭",
    emoji: "🇵🇾",
  },
  {
    name: "巴西",
    emoji: "🇧🇷",
  },
  {
    name: "阿联酋",
    emoji: "🇦🇪",
  },
  {
    name: "柬埔寨",
    emoji: "🇰🇭",
  },
  {
    name: "缅甸",
    emoji: "🇲🇲",
  },
  {
    name: "印尼",
    emoji: "🇮🇩",
  },
  {
    name: "马来西亚",
    emoji: "🇲🇾",
  },
  {
    name: "肯尼亚",
    emoji: "🇰🇪",
  },
  {
    name: "乌干达",
    emoji: "🇺🇬",
  },
  {
    name: "坦桑尼亚",
    emoji: "🇹🇿",
  },
  {
    name: "南非",
    emoji: "🇿🇦",
  },
  {
    name: "黎巴嫩",
    emoji: "🇱🇧",
  },
  {
    name: "韩国",
    emoji: "🇰🇷",
  },
  {
    name: "丹麦",
    emoji: "🇩🇰",
  },
];

export function getFlag(countryName) {
  const cur = flags.find(function (item) {
    return item.name === countryName;
  });
  return cur ? cur.emoji : "";
}
const getScale = () => {
  if (window.innerHeight > 1500) {
    return 1;
  } else if (window.innerHeight > 1000) {
    return 0.8;
  }
  return 0.5;
};

export const ScaleOffset = getScale();

export const autoHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    console.log(vh);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
