import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 以触发 fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 可以将错误日志记录到一个服务
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义错误界面
      return (
        <div className="error-boundary">
          <h1>出错了</h1>
          <p>我们遇到了一些问题。请稍后再试。</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
