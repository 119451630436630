import { useEffect, useState } from "react";
import { Button, Radio, Input, message, Modal } from "antd";
import classNames from "classnames";
import FileUpload from "../../components/FileUpload";
import "./Report.scss";
import { autoHeight } from "../../utils/helper";
import { ReportList } from "../../utils/report";

const xunfuBridge = window.xunfuBridge || {};

const { TextArea } = Input;

const UploadUrl = "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload";

const Report = () => {
  const [reportType, setReportValue] = useState();
  const [fileList, setFileList] = useState([]);
  const [desc, setDesc] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [page, setPage] = useState(1);

  useEffect(() => {
    document.title = "账号举报";
    autoHeight();
  }, []);

  const onChangeReportType = (e) => {
    e.preventDefault();
    setReportValue(e.target.value);
  };
  const handleCheck = (name) => {
    setReportValue(name);
  };

  const onChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  const handleClick = () => {
    if (!reportType) {
      messageApi.open({
        type: "warning",
        content: "请选择类型",
      });
      return;
    }
    if (!(fileList && fileList.length)) {
      messageApi.open({
        type: "warning",
        content: "请上传图片凭证",
      });
      return;
    }
    if (!xunfuBridge.getUid) {
      messageApi.open({
        type: "warning",
        content: "没有获取到用户id",
      });
      return;
    }

    const data = {
      type: reportType,
      pics: fileList.map((item) => item.name),
      desc,
      uid: xunfuBridge.getUid ? xunfuBridge.getUid() : "",
      targetUid: xunfuBridge.getTargetUid ? xunfuBridge.getTargetUid() : "",
    };
    // post

    Modal.success({
      title: "success",
      content: JSON.stringify(data), // 提交成功
    });
  };

  const next = () => {
    setPage(2);
  };

  const getName = () => {
    const data = ReportList.find((item) => item.type === reportType);
    return data ? data.name : "";
  };

  const getDesc = () => {
    return reportType === "fraud"
      ? `请详细描述被骗过程，包括但不限于与对方联系沟通的途径，受骗金额以及对方通过何种手段骗取财物。
图片/视频材料包括但不限于聊天记录、转账截图及能证明诈骗者身份等内容`
      : "请详细描述举报原因";
  };
  return (
    <div className="report">
      {page === 1 && (
        <div className="page1">
          <Radio.Group
            className="list"
            onChange={onChangeReportType}
            value={reportType}
          >
            {ReportList.map((item) => (
              <div
                className={classNames("item", {
                  active: reportType === item.type,
                })}
                key={item.type}
                onClick={() => handleCheck(item.type)}
              >
                <div className="row">
                  <div className="name">{item.name}</div>
                  <Radio value={item.type}></Radio>
                </div>
                {reportType === item.type && (
                  <div className="desc">{item.desc}</div>
                )}
              </div>
            ))}
          </Radio.Group>
          <Button
            disabled={!reportType}
            size="large"
            type="primary"
            className="btn"
            onClick={next}
          >
            下一步
          </Button>
        </div>
      )}
      {page === 2 && (
        <div className="page2">
          <div className="main">
            <div className="title">
              <div className="label">举报理由: {getName()}</div>
            </div>
            <div className="title">
              <div className="label">
                举报描述
                <span className="star">*</span>
              </div>
              <div className="info">最多500字</div>
            </div>
            <div className="desc-wrapper">
              <TextArea
                className="desc"
                showCount
                style={{ height: 250 }}
                maxLength={500}
                onChange={onChangeDesc}
                placeholder={getDesc()}
              />
            </div>

            <div className="title">
              <div className="label">
                图片/视频材料提交
                <span className="star">*</span>
              </div>
              <div className="info">最多4张</div>
            </div>
            <div className="upload-area">
              <FileUpload
                max={4}
                url={UploadUrl}
                fileList={fileList}
                onChange={setFileList}
              />
            </div>
          </div>
          <Button
            size="large"
            className="sub-btn"
            type="primary"
            onClick={handleClick}
          >
            提交
          </Button>
        </div>
      )}
      {contextHolder}
    </div>
  );
};

export default Report;
